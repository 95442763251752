<template>
    <ul class="mt-auto sub_menu">
        <h2 class="d-md-none">{{ $t('header.tools') }}</h2>

        <li>
            <a href="" v-on:click.prevent="logout">
                <i v-b-tooltip.right title="Déconnexion">
                    <font-awesome-icon :icon="['fal', 'door-open']" />
                </i> {{ $t('header.logout') }}
            </a>
        </li>
    </ul>
</template>

<script>
import Constants from 'Constants'
import User from '@/mixins/User.js'

export default {
    mixins: [User],
    components: {

	},
	data() {
		return {
			hide_tooltip: false,
			menus_notif: {
				title: 'Notifications',
				icon: ['fal', 'bell'],
				children: [
					{
						title: 'Actes planifiés',
						icon: ['fal', 'bell'],
						action: this.sendActsTodoTestNotification
					}, {
						title: 'Actes en retard',
						icon: ['fal', 'bell'],
						action: this.sendActsDelayedTestNotification
					}, {
						title: 'Actes nécessitant une ordonnace',
						icon: ['fal', 'bell'],
						action: this.sendActsDocumentTestNotification
					}, {
						title: 'Repro quotidienne',
						icon: ['fal', 'bell'],
						action: this.sendActsReproDaily
					}, {
						title: 'Repro hebdo',
						icon: ['fal', 'bell'],
						action: this.sendActsReproWeekly
					}, {
						title: 'Repro stats',
						icon: ['fal', 'bell'],
						action: this.sendActsReproStats
					}, {
						title: 'Repro rappel poulinage',
						icon: ['fal', 'bell'],
						action: this.sendActsReproTerm
					}, {
						title: 'Rappel registre',
						icon: ['fal', 'bell'],
						action: this.sendRegistryReminderTestNotification
					}
				]
			}
		}
	},
    computed: {
        userAccess() {
            return this.$store.state.userAccess
        }
    },
    methods: {
        async logout() {
            await this.delogUser()
            this.$store.commit('nav/closeFooter')
        },
        async reload() {
            let licence_key = this.getConfig("licence_key")
            let token = this.getConfig("token")
            await this.rmConfig();
            this.setConfig("licence_key", licence_key)
            this.setConfig("token", token)
            await this.loadConfig()
            location.reload()
        },
        sendSampleNotification() {
            const url = `${Constants.BASE_API_URL}/api/notifications/sample/${Constants.USER_LICENCE_KEY}/${this.getConfig('user_id')}`
            this.$request.request_post_api('Notification::sendSample', url)
		},
		sendActsTodoTestNotification() {
            const url = `${Constants.BASE_API_URL}/api/notifications/test_act_todo/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendActsTodoTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
		sendActsDelayedTestNotification() {
            const url = `${Constants.BASE_API_URL}/api/notifications/test_act_delay/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendActsDelayedTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
		sendActsDocumentTestNotification() {
            const url = `${Constants.BASE_API_URL}/api/notifications/test_act_document/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendActsDocumentTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
		sendActsReproDaily() {
            const url = `${Constants.BASE_API_URL}/api/notifications/test_act_repro_daily/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendActsDocumentTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
		sendActsReproWeekly() {
            const url = `${Constants.BASE_API_URL}/api/notifications/test_act_repro_weekly/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendActsDocumentTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
		sendActsReproStats() {
            const url = `${Constants.BASE_API_URL}/api/notifications/test_act_repro_stats/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendActsDocumentTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
		sendActsReproTerm() {
            const url = `${Constants.BASE_API_URL}/api/notifications/test_act_repro_term/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendActsDocumentTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
		sendRegistryReminderTestNotification() {
            const url = `${Constants.BASE_API_URL}/api/notifications/test_registry_reminder/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendRegistryReminderTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
    }
}
</script>